<template>
  <div class="d-flex flex-column justify-center align-start">
    <div class="d-flex flex-row justify-center align-start">
      <v-list>
        <v-list-item-group
          v-model="colorSelected"
          active-class="indigo--text"
          mandatory
          class="d-flex flex-wrap"
        >
          <v-list-item class="pa-2 mx-2" 
            style="max-width: 225px;" 
            v-for="(value, key, index) in renameColors" 
            :key="index" 
            :value="key"
          >
            <v-list-item-content>
              <v-list-item-title v-if="colorsT[key]" v-text="value"></v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-icon
                style="border: 1px solid #1b1b1b;"
                :color="colorsT[key]"
              >
                mdi-square
              </v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-list> 
      <ColorPickerTool class="order-last" v-if="colorsT[colorSelected]" v-model="colorsT[colorSelected]" @change="setNewColor"/>
    </div>
  </div>
</template>

<script>
import ColorPickerTool from '../../Tools/colorPickerTool.vue';

export default {
  name: "colorConfigTool",
  props: ["colors", "darkMode"],
  components: {
    ColorPickerTool
  },
  data() {
    return {
      colorSelected: false,
      colorsT: this.colors,
      colorsTCopy: JSON.stringify(this.colors),
      renameColors: {
        "--color-primary": "Color principal",
        "--color-bg": "Color Home",
        "--color-bg-others": "Color secciones",
        "--color-header": "Color encabezado",
        "--color-primary-light": "Color Der. Encabezado",
        "--color-primary-light1": "Color Izq. Encabezado",
        "--color-footer": "Color pie página",
        "--color-primary-hover": "Color scroll",
        "--color-seating-flag": "Bandera de Butacas",
        "--color-schedule-flag": "Bandera de fechas",
        "--color-hyperlink-flag": "Bandera Hipervínculo",
        "--color-soon-flag": "Bandera 'Próx.'",
      },
      changeDetected: false
    };
  },
  methods: {
    setNewColor(value) {
      if(!this.changeDetected) {
        if(this.colorsTCopy !== JSON.stringify(this.colorsT)) {
          this.$emit("changeDetected", 0)
          this.changeDetected = true
        }
      }
      document.documentElement.style.setProperty(this.colorSelected, value);
    },
    discardChanges() {
      this.colorsT = JSON.parse(this.colorsTCopy)
      this.$emit('updateColors', this.colorsT)
      setTimeout(() => {
        this.$emit("changeDetected", false)
        this.changeDetected = false
      }, 300)
    },
    saveColors() {
      this.$root.post("/Admin/updateCorpColors", {
        darkMode: this.darkMode,
        colors: this.colorsT
      })
      .then((response) => {
        let info = response.data;
        if(info.success == 'TRUE') {
          const response = info.Data
          this.colorsT = response.colors
          this.colorsTCopy = JSON.stringify(this.colors)
          this.$emit('updateColors', this.colorsT, response.dark_on)
          this.colorsTCopy = JSON.stringify(this.colorsT)
          this.$root.Toast("success", "Colores actualizados");
        } else {
          this.$root.swalAlert("error", "Error al guardar", info.Msg);
        }
      })
      .catch(e => {
        this.$root.swalAlert("error", "Error", e.message);
      }).
      finally(() => {        
        this.changeDetected = false
        this.$emit("changeDetected", false)
      });
    }
  }
}
</script>

<style scoped>

</style>