<template>
  <div style="height: 100%; width: 100%; background-color: var(--color-footer);"
  class="py-2">
    <div v-if="footerT && footerT.length > 0" class="px-2">
      <draggable
        v-model="footerT"
        draggable=".itemDragList" class="row-drag">
        <v-hover v-slot="{ hover }" 
            v-for="(info, index) in footerT" 
            :key="'drag'+index"
            class="itemDragList column-style">
          <v-col style="position: relative;" 
            class="d-flex flex-column justify-start align-center"
            @click="typeOfColumnConfig(info, index)"
          >
            <v-btn 
            v-if="hover" 
            small dark fab 
            class="btn-style"
            @click="deleteColumn(info)"
            >
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>

            <v-img
              v-if="info.logo"
              contain 
              :src="$root.UrlCorpImg + info.logo"
              max-height="70"
              max-width="120"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    :color="dark?'white':'grey lighten-5'"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <div class="py-4 name-title" 
            style="width: 100%;"
            v-html="info.html"
            ></div>
          </v-col>
        </v-hover>
      </draggable>
    </div>

    <v-dialog
      persistent
      no-click-animation
      v-model="columnDialog"
      width="900"
    >
      <v-card>
        <v-toolbar
          dark
          color="grey darken-4"
        >
          <v-toolbar-title class="overline grey--text text--lighten-2">
            {{ columnIndex ? 'Editar Columna':'Nueva Columna' }}
          </v-toolbar-title>
        </v-toolbar>

        <v-card-text class="px-3 d-flex flex-column justify-center align-stretch">
          <v-row class="text-center py-6">
            <v-col cols="12" 
            v-if="!columnImage && !footerColumn['logo']"
            class="d-flex justify-center align-center" 
            >
              <v-btn class="py-9" x-large @click="selectFile">
                <v-icon size="40">mdi-image-plus</v-icon>
              </v-btn>
            </v-col>
            <v-col v-else cols="4" class="mx-auto" style="position: relative;">
              <v-btn fab 
                v-if="footerColumn['logo']"
                small 
                style="position: absolute; top: 0; right: calc(0%);"
                @click="() => {
                  deletedImage = footerColumn['logo']
                  footerColumn['logo'] = null
                }"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-img
                @click="selectFile"
                class="mx-auto"
                contain 
                :src="columnImage?columnImage:$root.UrlCorpImg + footerColumn['logo']"
                max-height="90"
                max-width="150"
              >
              </v-img>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-tiptap
              :toolbar="['bold', 'italic', 'underline','strike', 'color', 'link', '|', 'headings', '|', 'bulletList', 'orderedList', 'left', 'center', 'right']" 
                v-model="footerColumn.html" 
              > 
              </v-tiptap>
            </v-col>
          </v-row>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            text
            @click="cancelEdition"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="black"
            dark
            @click="saveColumn"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "footerConfigTool",
  props: ["footerInfo", "dark"],
  components: {
    draggable
  },
  data () {
    return {
      footerT: this.footerInfo,
      deleteFlag: false,
      
      columnImage: false,
      deletedImage: false,
      file: false,
      
      columnDialog: false,
      currentColumn: false,
      columnIndex: false,
      footerColumn: {
        logo: null,
        html: ""
      },

      footerInfoCopy: JSON.stringify(this.footerInfo),
      changeDetected: false
    }
  },
  watch: {
    footerT: function (val) {
      if(!this.changeDetected) {
        this.changeDetected = true
        this.$emit("changeDetected", 2)
      }
    }
  },
  methods: {    
    discardChanges() {
      this.footerT = JSON.parse(this.footerInfoCopy)
      setTimeout(() => {
        this.$emit("changeDetected", false)
        this.changeDetected = false
      }, 300)
    },
    async selectFile () {
      let self = this;
      this.file = false

      const { value: file } = await this.$swal({
        title: 'Select image',
        input: 'file',
        inputAttributes: {
          'accept': 'image/*',
          'aria-label': 'Upload your profile picture'
        }
      })
      this.file = file;
      
      if (this.file) {
        if ( this.file.type !== "image/png" && this.file.type !== "image/jpeg" 
            && this.file.type !== "image/jpg" && this.file.type !== "image/webp" 
            && this.file.type !== "image/svg" && this.file.type !== "image/svg+xml" 
          ) {
          this.file = false
          return this.$root.swalAlert("error", "Archivo no permitido", "Sólo se admiten archivos JPG, JPEG, PNG, WEBP o SVG");
        }
        self.columnImage = false
        const reader = new FileReader()
        reader.onload = (e) => {
          self.file = e.target.result;
          self.columnImage = e.target.result;
        }
        reader.readAsDataURL(self.file)
      }
    },
    typeOfColumnConfig(columnInfo={ logo: null, html: "" }, index=false) {
      this.columnIndex = index
      this.columnImage = false
      this.footerColumn = {...columnInfo}
      if(!this.deleteFlag) this.columnDialog = true
    },
    deleteColumn(column) {
      this.deleteFlag = true
      this.$root
      .$swal({
        title: "¿Está seguro(a) de borrar esta columna?",
        text: "Una vez eliminado se perderán los datos",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, estoy seguro(a)",
      })
      .then((response) => {
        if (response.isConfirmed) {
          this.$root.post("/Admin/deleteFooterColumn", {
            column
          })
          .then((response) => {
            let info = response.data;
            if(info.success == 'TRUE') {
              const response = info.Data
              this.footerT = response.footerinfo
              this.footerInfoCopy = JSON.stringify(this.footerT)
              this.$emit("updatefooter", response.footerinfo, response.dark_on)
              this.$root.Toast("success", "Columna eliminada");
            } else {
              this.$root.swalAlert("error", "Error al eliminar", info.Msg);
            }
          })
          .catch(e => {
            this.$root.swalAlert("error", "Error", e.message); 
          })
          .finally(() => {
            this.changeDetected = false
            this.$emit("changeDetected", false)
          })
        }
        this.deleteFlag = false
      });
    },
    saveOrden() {
      this.$root.post("/Admin/updateFooterOrden", {
        darkMode: this.dark, 
        footer: this.footerT
      })
      .then((response) => {
        let info = response.data;
        if(info.success == 'TRUE') {
          const response = info.Data
          this.footerT = response.footerinfo
          this.footerInfoCopy = JSON.stringify(this.footerT)
          this.$emit("updatefooter", response.footerinfo, response.dark_on)
          this.$root.Toast("success", "Orden de columnas actualizado");
        } else {
          this.$root.swalAlert("error", "Error al guardar", info.Msg);
        }
      })
      .catch(e => {
        this.$root.swalAlert("error", "Error", e.message); 
      })
      .finally(() => {        
        this.changeDetected = false
        this.$emit("changeDetected", false)
      });
    },
    saveColumn() {
      this.$root.post("/Admin/updateFooterColumn", {
        column: this.footerColumn, 
        newImage: this.columnImage,
        deletedImage: this.deletedImage,
        oldColumn: this.footerT[this.columnIndex]
      })
      .then((response) => {
        let info = response.data;
        if(info.success == 'TRUE') {
          const response = info.Data
          this.footerT = response.footerinfo
          this.footerInfoCopy = JSON.stringify(this.footerT)
          this.$emit("updatefooter", response.footerinfo, response.dark_on)
          this.$root.Toast("success", "Columna agregada");
        } else {
          this.$root.swalAlert("error", "Error al guardar", info.Msg);
        }
      })
      .catch(e => {
        this.$root.swalAlert("error", "Error", e.message); 
      })
      .finally(() => {
        this.columnImage = false
        this.deletedImage = false
        this.columnDialog = false
        this.changeDetected = false
        this.$emit("changeDetected", false)
      });
    },
    cancelEdition() {
      this.columnDialog = false
    }
  }
}
</script>

<style scoped>
.row-drag {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
.column-style {  
  width: 100%;
  cursor: pointer;
  margin: 0 !important;
}
.column-style:hover {
  box-shadow: 0px 0px 5px 0px #383838 !important;
}
.name-title {
  overflow: hidden;
  width: 100% !important;
  word-wrap: wrap;
}
.btn-style {
  position: absolute;
  top: 0px;
  right: 0px;
}
</style>