<template>
  <v-card class="pa-0" width="100%" height="100%" style="overflow: hidden;">
    <div class="nav-bar d-flex align-center justify-space-between" 
    :class="[{'white--text': dark}, {'black--text': !dark}]">
      <div>LOGO</div>
      <div class="d-flex flex-row">
        <v-icon class="mx-1" :dark="dark" size="1.7em">mdi-cart-outline</v-icon>
        <div class="mx-1 d-flex flex-row">
          <v-icon :dark="dark" size="1.5em">mdi-translate</v-icon>
          <div :class="[{'white--text': dark}]">ES</div>
        </div>
        <v-icon class="mx-1" :dark="dark" size="2em">mdi-account-circle</v-icon>
      </div>
    </div>
    <div class="images-bar d-flex flex-column justify-center"> 
      <div class="px-2 d-flex align-center justify-space-between overline">
        <v-icon dark size="1.1em" color="grey lighten-2">mdi-chevron-left-circle</v-icon>
        imágenes
        <v-icon dark size="1.1em" color="grey lighten-2">mdi-chevron-right-circle</v-icon>
      </div>
      <div class="ma-0 pa-0 mb-3 images-ticket-bar d-flex flex-row justify-start align-center">
        <div class="container-ticket mx-auto">
          <div class="find-button d-flex justify-center align-center" :class="[{'white--text': dark}]">
            VER BOLETOS
          </div>
        </div>
      </div>
    </div>
    <div class="container-events px-5 d-flex flex-row justify-space-around align-center">
      <div class="card-event d-flex flex-column justify-end align-center"
        v-for="event in events" 
        :key="event.id_event">
         <div class="d-flex justify-center cover-style">
          <v-icon dark>mdi-image</v-icon>
         </div>
         <div class="mt-1 white--text title-style">{{ event.name }}</div>
         <div class="white--text type-style px-1 d-flex flex-row align-center"> 
          <div class="line-style"></div>
          <div class="px-1">{{ event.typeEvent }}</div>          
          <div class="line-style"></div>
         </div>
      </div>
    </div>
    <div class="footer-style" :class="[{'white--text': dark}, {'black--text': !dark}]">
      ayuda
    </div>
  </v-card>
</template>

<script>
export default {
  name: "littleMainView",
  props: ["dark"],
  data () {
    return {
      events: [
        {
          id_event: 1,
          name: "Evento 1",
          typeEvent: "Taller"
        },
        {
          id_event: 2,
          name: "Evento 2",
          typeEvent: "Evento"
        },
        {
          id_event: 3,
          name: "Evento 3",
          typeEvent: "Teatro"
        }
      ]
    }
  }
}
</script>

<style scoped>
  .nav-bar {
    border-bottom: 1px solid var(--color-primary);
    height: 10%;
    font-size: 0.4em;
    padding: 0% 5%;
    background-color: var(--color-header);
  }
  .images-bar {
    background-color: rgb(151, 151, 151);
    height: 30%;
  }
  .container-events {
    background-color: var(--color-bg);
    padding: 0.6em 1em;
    height: 40%;
  }
  .card-event {
    width: 4em;
    background-color: black;
    line-height: 0.5em;
  }
  .title-style {
    font-size: 0.4em;
  }
  .type-style {
    font-size: 0.4em;
    width: 100%;
  }
  .cover-style {    
    height: 2em;
    width: 100%;
    background-color: grey;
  }
  .line-style {
    border: 0.1em solid rgb(165, 165, 165);
    width: 100%;
    height: 0.1em;
  }
  .footer-style {
    height: 20%;
    background-color: var(--color-footer);
    padding: 0.3% 5%;
    font-size: 0.4em;
  }
  .images-ticket-bar {
    background-color: #353538a6;
    height: 0.9rem;
  }
  .find-button {
    background-color: var(--color-primary);
    width: fit-content;
    height: 0.7rem;
    font-size: 0.2em;
    padding: 0rem 0.3rem;
  }
  .container-ticket {
    width: 60%;
  }
</style>