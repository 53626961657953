<template>
  <v-card class="pa-0" width="100%" height="100%" style="overflow: hidden;"
    :style="{
      'background': `linear-gradient(
        to bottom,
        ${dark ? 
        '#000000 0%, var(--color-bg-others) 100%':'#E2E6EF 0%, var(--color-bg-others) 100%'}
      )`
    }"
  >
    <div class="nav-bar d-flex align-center justify-space-around" 
      :class="[{'white--text': dark}, {'black--text': !dark}]">
      <div>LOGO</div>
      <div class="d-flex flex-column justify-center align-center title-nav"
        :class="[{'white--text': dark}, {'black--text': !dark}]">
        <div>Evento 1</div>
        <div>Domingo 31 de Diciembre, 15:00, Lugar de Evento</div>
      </div>
      <v-icon class="mx-1" :dark="dark" size="1.7em">mdi-circle-outline</v-icon>
    </div>
    <div class="background-pay" :style="{'background-color': dark?'#00000080':'#ffffffa6'}">
      <div class="box-details d-flex flex-column justify-start px-2" 
        :style="{'background-color': dark ? '#818181B3' : '#FFFFFFB3'}">
        <div class="py-1 d-flex justify-space-around align-center">
          <v-icon dark color="var(--color-primary)" size="1em">mdi-numeric-1-circle</v-icon>
          <v-icon dark color="var(--color-primary)" size="1em">mdi-numeric-2-circle</v-icon>
        </div>
        <v-icon class="pa-0 ma-0" size="2.5em">mdi-image</v-icon>
        <div class="boletos-contain d-flex justify-start align-center"
          :class="[{'white--text': dark}, {'black--text': !dark}]">
          <v-icon size="0.7em"
            color="var(--color-primary)" 
            style="transform: rotate(135deg)">
            mdi-ticket-outline
          </v-icon>&nbsp; x1 Boleto(s)
        </div>
        <div class="table-details d-flex flex-column justify-start align-center">
          <div class="d-flex flex-row justify-center align-center">
            <div v-for="v in 8" :key="v" 
              class="header-pay d-flex align-center justify-center"
              :style="dark?styleHeaderDark:styleHeaderLight">A</div>
          </div>
          <div v-for="v in 2" :key="'b-'+ v" class="d-flex flex-row justify-center align-center">
            <div v-for="v in 8" :key="'bb-'+ v" 
              class="body-pay d-flex align-center justify-center"
              :style="dark?styleBodyDark:styleBodyLight">B</div>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "miniPaymentConfirmationView",
  props: ["dark"],
  data() {
    return {
      styleHeaderDark: {
      'background': 'black',
      'color': '#D5D5D5'
      },
      styleHeaderLight: {
        'background': 'white',
        'color': 'black'
      },
      styleBodyDark: {
        'background': '#252525',
        'color': '#D4D4D4'
      },
      styleBodyLight: {
        'background': '#F0F0F0',
        'color': '#3B3B3B'
      },
    }
  }
}
</script>

<style scoped>
  .nav-bar {
    height: 10%;
    font-size: 0.4em;
    padding: 1% 5%;
    background: linear-gradient( 207deg, 
        var(--color-primary-light) 0%, 
        var(--color-primary) 45%, 
        var(--color-primary-light1) 99% 
      ) !important;
  }
  .title-nav {
    line-height: 1em;
  }
  .background-pay {
    position: relative;
    height: 90%;
    width: 100%;
  }
  .box-details {
    position: absolute;
    top: calc(10%);
    left: calc(30%);
    width: 40%;
    height: 70%;
  }
  .boletos-contain {
    border-top: 0.2em solid var(--color-primary);
    font-size: 0.4em;
  }
  .table-details {
    height: 1.5em;
    overflow: hidden; 
  }
  .header-pay {
    padding: 0.5em 1em;
    margin: 0em 0.1em;
    max-height: 0.7em;
    width: 1.7em;
    font-size: 0.4em;
  }
  .body-pay {
    padding: 0.5em 1em;
    margin: 0.1em 0.1em;
    max-height: 0.7em;
    width: 1.7em;   
    font-size: 0.4em;
  }
</style>