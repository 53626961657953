<template>
  <v-card class="pa-0" width="100%" height="100%" style="overflow: hidden;" 
    :style="{
      'background': `linear-gradient(
        to bottom,
        ${dark ? 
        '#000000 0%, var(--color-bg-others) 100%':'#E2E6EF 0%, var(--color-bg-others) 100%'}
      )`,
      'background-size': 'cover',
      'background-position': '50% 50%',
      'background-blend-mode': 'multiply'
    }"
  >
    <div class="nav-bar d-flex align-center justify-space-between" 
    :class="[{'white--text': dark}, {'black--text': !dark}]">
      <div>LOGO</div>
      <div class="d-flex flex-row">
        <v-icon class="mx-1" :dark="dark" size="1.7em">mdi-cart-outline</v-icon>
        <div class="mx-1 d-flex flex-row">
          <v-icon :dark="dark" size="1.5em">mdi-translate</v-icon>
          <div>ES</div>
        </div>
        <v-icon class="mx-1" :dark="dark" size="2em">mdi-account-circle</v-icon>
      </div>
    </div>
    
    <div class="d-flex flex-column justify-center"> 
      <div class="px-3 icon-style"
        :class="[{'white--text': dark}, {'black--text': !dark}]">
        <v-icon :dark="dark" size="1.5em">mdi-arrow-left</v-icon> REGRESAR
      </div>
    </div>

    <div class="px-5 d-flex flex-row justify-space-between" 
      :class="[{'white--text': dark}, {'black--text': !dark}]"> 
      <div>
        <div class="icon-style carrito-style" :style="{'border-bottom': dark ? '1px solid white' : '1px solid black'}">
          CARRITO
        </div>
        <div class="table-style d-flex">
            <div>SITIO</div>
            <div class="px-1">BOLETO(S)</div>
            <div class="px-1">CANTIDAD</div>
            <div>PRECIO UNITARIO</div>
        </div>
        <div class="carrito-style" style="text-align:center;" 
          :style="{'border-bottom': dark ? '1px solid white' : '1px solid black'}">
          <v-icon :color="dark ? 'grey lighten-3' : 'black'" size="0.7em">mdi-chevron-left</v-icon>
          <v-icon :color="dark ? 'grey lighten-3' : 'black'" size="0.7em">mdi-chevron-right</v-icon>
        </div>
      </div>

      <div class="my-4 title2-style border-styel">
        RESUMEN DE PAGO
        <div class="d-flex flex-row justify-space-around total-style">
          <div>
            TOTAL
          </div>
          <div>
            $0.00 MXN
          </div>
        </div>
      </div>
    </div>

    <div class="px-4 mt-n4 title-style" :class="[{'white--text': dark}, {'black--text': !dark}]">
        Esto podría interesarte...
    </div>
    <div class="py-0 px-5 d-flex flex-row justify-space-around align-center">
      <div class="card-event d-flex flex-column justify-end align-center"
        v-for="event in events" 
        :key="event.id_event">
         <div class="d-flex justify-center cover-style">
          <v-icon dark size="1em">mdi-image</v-icon>
         </div>
         <div class="mt-1 white--text title-style">{{ event.name }}</div>
         <div class="white--text type-style px-1 d-flex flex-row align-center"> 
          <div class="line-style"></div>
          <div class="px-1">{{ event.typeEvent }}</div>          
          <div class="line-style"></div>
         </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "littleMainView",
  props: ["dark"],
  data () {
    return {
      events: [
        {
          id_event: 1,
          name: "Evento 1",
          typeEvent: "Taller"
        },
        {
          id_event: 2,
          name: "Evento 2",
          typeEvent: "Evento"
        },
        {
          id_event: 3,
          name: "Evento 3",
          typeEvent: "Teatro"
        }
      ]
    }
  }
}
</script>

<style scoped>
  .nav-bar {
    border-bottom: 1px solid var(--color-primary);
    height: 1.2rem;
    font-size: 0.4em;
    padding: 0% 5%;
    background-color: var(--color-header);
  }
  .border-styel{
    border: 1px solid;
    padding: 0px 3px 0px 3px;
  }
  .carrito-style{
    margin-bottom: 1em;
  }
  .card-event {
    width: 3em;
    background-color: black;
    line-height: 0.4em;
  }
  .total-style{
    margin-top: -1em;
  }
  .table-style{
    font-size: 0.4em;
    margin-top: -2em;
  }
  .title-style {
    font-size: 0.4em;
  }
  .title2-style {
    font-size: 0.5em;
  }
  .icon-style {
    font-size: 0.4em;
  }
  .type-style {
    font-size: 0.4em;
    width: 100%;
  }
  .cover-style {    
    height: 1em;
    width: 100%;
    background-color: grey;
  }
  .line-style {
    border: 0.1em solid #a5a5a5;
    width: 100%;
    height: 0.1em;
  }
</style>