<template>
  <div class="py-8 ma-0 d-flex flex-row justify-start grey lighten-5">
    <v-card
      :loading="loading"
      class="mx-auto d-flex flex-column justify-end grey lighten-3"
      width="400"
      height="200"
    >
      <template slot="progress">
        <v-progress-linear
          color="black"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>

      <div v-if="newLogoImage || logoT" 
        class="d-flex justify-center align-center" 
        style="cursor: pointer; position: relative; width: 100%; height: 100%;">
        <v-img
          :src="newLogoImage?newLogoImage:$root.UrlCorpImg + logoT"
          contain
          max-height="100"
          max-width="200"
          @click="selectLogoFile"
        > </v-img>
        <v-btn fab small 
          style="position: absolute; top: calc(0%); right: calc(0%);"
          @click="removeImage('logo')">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
      <v-btn v-if="!newLogoImage && !logoT" class="py-9 ma-auto" x-large @click="selectLogoFile">
        <v-icon size="40">mdi-image-plus</v-icon>
      </v-btn>

      <v-card-title class="overline">Logo de empresa para Webshop (Opcional)</v-card-title>
    </v-card>

    <v-card
      :loading="loading"
      class="mx-auto d-flex flex-column justify-end grey lighten-3"
      width="400"
    >
      <template slot="progress">
        <v-progress-linear
          color="deep-purple"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>

      <div v-if="newBackgroundImage || bgImageT" 
        class="d-flex justify-center align-center" 
        style="cursor: pointer; position: relative; width: 100%; height: 100%;">
        <v-img class="ma-auto"
          :src="newBackgroundImage?newBackgroundImage:$root.UrlCorpImg + bgImageT"
          contain
          max-height="100"
          max-width="200"
          @click="selectBgFile"
        ></v-img>
        <v-btn fab small 
          style="position: absolute; top: calc(0%); right: calc(0%);"
          @click="removeImage('bgImage')">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
      <v-btn v-if="!newBackgroundImage && !bgImageT" class="py-9 ma-auto" x-large @click="selectBgFile">
        <v-icon size="40">mdi-image-plus</v-icon>
      </v-btn>

      <v-card-title class="overline">Imagen de fondo para Webshop (Opcional)</v-card-title>
    </v-card>
  </div>
</template>

<script>
export default {
  name:"imagesConfigTool",
  props:["bgImage", "logo", "loading"],
  data(){
    return {
      bgImageT: this.bgImage,
      logoT: this.logo,

      deletedLogo: false,
      deletedBg: false,

      newBackgroundImage: false,
      newLogoImage: false,
      file: false,

      logoCopy: this.logo,
      bgCopy: this.bgImage
    }
  },
  methods: {
    discardChanges() {
      this.bgImageT = this.bgCopy
      this.logoT = this.logoCopy
      this.newLogoImage = false
      this.deletedLogo = false
      this.newBackgroundImage = false
      this.deletedBg = false
      this.$emit("changeDetected", false)
    },
    async selectLogoFile () {
      let self = this;
      this.file = false
      const { value: file } = await this.$swal({
        title: 'Select image',
        input: 'file',
        inputAttributes: {
          'accept': 'image/*',
          'aria-label': 'Upload your profile picture'
        }
      })
      this.file = file;
      
      if (this.file) {
        if ( this.file.type !== "image/png" && this.file.type !== "image/jpeg" 
            && this.file.type !== "image/jpg" && this.file.type !== "image/webp" 
            && this.file.type !== "image/svg" && this.file.type !== "image/svg+xml" 
          ) {
          this.file = false
          return this.$root.swalAlert("error", "Archivo no permitido", "Sólo se admiten archivos JPG, JPEG, PNG, WEBP o SVG");
        }
        this.newLogoImage = false

        const reader = new FileReader()
        reader.onload = (e) => {
          self.file = e.target.result;
          self.newLogoImage = e.target.result
        }
        reader.readAsDataURL(self.file)
        
        this.$emit("changeDetected", 1)
      }
    },
    async selectBgFile () {
      let self = this;
      this.file = false
      const { value: file } = await this.$swal({
        title: 'Select image',
        input: 'file',
        inputAttributes: {
          'accept': 'image/*',
          'aria-label': 'Upload your profile picture'
        }
      })
      this.file = file;
      
      if (this.file) {
        if ( this.file.type !== "image/png" && this.file.type !== "image/jpeg" 
            && this.file.type !== "image/jpg" && this.file.type !== "image/webp" 
            && this.file.type !== "image/svg" && this.file.type !== "image/svg+xml" 
          ) {
          this.file = false
          return this.$root.swalAlert("error", "Archivo no permitido", "Sólo se admiten archivos JPG, JPEG, PNG, WEBP");
        }
        this.newBackgroundImage = false

        const reader = new FileReader()
        reader.onload = (e) => {
          self.file = e.target.result;
          self.newBackgroundImage = e.target.result
        }
        reader.readAsDataURL(self.file)
        
        this.$emit("changeDetected", 1)
      }
    },
    removeImage(type) {
      if(type === 'logo') {
        this.newLogoImage = false
        this.deletedLogo = this.logoT
        this.logoT = null
      }
      if(type === 'bgImage') {        
        this.newBackgroundImage = false
        this.deletedBg = this.bgImageT
        this.bgImageT = null
      }      
      this.$emit("changeDetected", 1)
    },
    saveChanges() {
      this.$root.post("/Admin/updateCorpImages", {
        logoImages: {
          newImage: this.newLogoImage,
          deletedImage: this.deletedLogo,
          currentImage: this.logoT
        },
        bgImages: {
          newImage: this.newBackgroundImage,
          deletedImage: this.deletedBg,          
          currentImage: this.bgImageT
        }
      })
      .then((response) => {
        let res = response.data;
        if(res.success == 'TRUE') {
          let info = res.Data
          this.bgImageT = info.bg_image
          this.logoT = info.logo
          this.bgCopy = this.bgImageT
          this.logoCopy = this.logoT          
          this.$root.Toast("success", "Imágenes actualizadas");
        } else {
          this.$root.swalAlert("error", "Error al cargar estilos", res.Msg);
        }
      })
      .catch(e => {        
        this.$root.swalAlert("error", "Error", e.message);
      })
      .finally(() => {
        this.newLogoImage = false
        this.deletedLogo = false
        this.newBackgroundImage = false
        this.deletedBg = false
        this.$emit("changeDetected", false)
      });
    }
  }
}
</script>