<template>
  <v-card class="pa-0" width="100%" height="100%" style="overflow: hidden;">
    <div class="map-bar px-7 white--text d-flex align-center justify-start">
      <div class="map-image d-flex justify-center"><v-icon>mdi-image</v-icon></div>
      <div class="font-size-map mx-5">
        <div><b>Evento 1</b></div>
        <div>16:00 HRS - domingo 4 de junio de 2023 </div>
        <div>Lugar del Evento, Mérida, Yucatán</div>
      </div>
    </div>
    <v-row class="pa-0 mx-auto my-auto" style="height: 81%;">
      <v-col style="position: relative;" cols="8" class="grey lighten-3 d-flex justify-center align-center">
        <div class="d-flex justify-space-between align-center map-buttons-container px-3 py-1">
          <div class="buttom-map-style d-flex justify-center align-center">
            <v-icon size="0.7em" :dark="dark">mdi-home</v-icon> 
          </div>
          <div class="d-flex flex-row justify-center align-center">
            <div class="mx-1 buttom-map-style d-flex justify-center align-center"><v-icon size="0.2em" :dark="dark">mdi-scan-helper</v-icon></div>
            <div class="buttom-map-style light d-flex justify-center align-center"><v-icon size="0.5em" color="var(--color-primary)">mdi-plus</v-icon></div>
            <div class="buttom-map-style light d-flex justify-center align-center"><v-icon size="0.5em" color="var(--color-primary)">mdi-minus</v-icon></div>
          </div>
        </div>
        <v-icon size="5em">mdi-image</v-icon>
      </v-col>
      <v-col cols="4" class="white pa-0 px-2">
        <div class="number-tickets d-flex flex-row justify-space-between align-center">
          <b>TOTAL $0.00 MX</b>
          <div>
            <v-icon size="1em" 
              color="var(--color-primary)" 
              style="transform: rotate(135deg)">
              mdi-ticket-outline
            </v-icon> x 1
          </div>
        </div>
        <v-card class="my-1" elevation="1" v-for="x in 2" :key="x">
          <div class="d-flex flex-row justify-space-between" style="border-bottom: 0.1px solid #e7e7e7;">
            <div class="d-flex flex-row justify-center align-stretch"
              v-for="(v, index) in 4" :key="index">
              <div class="card-titles mx-1">TITULO</div>
              <div class="line-card my-1" v-if="index !== 3"></div>
            </div>
          </div>
          <div class="px-2 d-flex flex-row justify-space-between">
            <div class="price-style">$</div>
            <div><v-icon color="var(--color-primary)" size="0.8em">mdi-delete</v-icon></div>
          </div>
        </v-card>
        <v-row>
          <v-col cols="12" class="d-flex justify-center align-center">
            <div class="buttom-style d-flex justify-center align-center" 
              :class="[{'white--text': dark}]">
              COMPRAR
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "littleMapView",
  props: ["dark"],
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>

<style scoped>
  .map-bar {
    height: 19%;
    background-color: #755d5d;
  }
  .map-image {
    background-color: #a18888;
    height: 2em;
    width: 3em;
  }
  .font-size-map {
    font-size: 0.5em;
    line-height: 1.3em;
  }
  .number-tickets {
    font-size: 0.5em;
  }
  .card-titles {
    color: var(--color-primary);
    font-size: 0.3em;
  }
  .line-card {
    border: 0.1px solid #e7e7e7;
  }
  .price-style {
    font-size: 0.6em;
  }
  .buttom-style {
    height: 1rem;
    width: 1.9rem;
    border-radius: 5px;
    background-color: var(--color-primary);
    font-size: 0.3em;
  }
  .map-buttons-container {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .buttom-map-style {
    height: 1.2em;
    width: 1.3em;
    background-color: var(--color-primary);
    border-radius: 5px;
  }
  .light {
    background-color: white;
  }
</style>