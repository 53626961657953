<template>
  <v-card class="pa-0 ma-0" flat style="height: 100%;">
    <v-toolbar
      flat 
      tile
      dense 
      dark
      style="height: 7%;"
      color="grey darken-4">
      <v-toolbar-title>CONFIGURACIÓN DE COLORES DE WEBSHOP</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="d-flex justify-center align-center">
        <v-btn v-if="changeOnTab !== false" 
          class="mr-5" 
          color="orange darken-4" 
          @click="discardAnyChanges"
          max-height="40px" 
        >
          Descartar Cambios
        </v-btn>
        <v-btn v-if="changeOnTab !== false"  
          color="#1565C0" 
          max-height="40px" 
          dark 
          @click="saveAnyChanges(false)">
          Guardar
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text style="max-height: 93%; overflow-y: auto !important;" class="mx-auto pa-0 pb-5">
      <div class="mb-2 text-center blue-grey lighten-5">VISTAS</div>
      <v-row class="mx-auto d-flex flex-row justify-space-around align-stretch" style="max-height: 14em; max-width: 1300px;">
        <v-col cols="3" class="max-miniView-size">
          <littleMainView :dark="dark"/>
        </v-col>
        <v-col cols="3" class="max-miniView-size">
          <littleMapView :dark="dark"/>
        </v-col>
        <v-col cols="3" class="max-miniView-size">
          <miniPayConfirmView :dark="dark"/>
        </v-col>
      </v-row>
      
      <v-row class="mx-auto d-flex flex-row justify-space-around align-stretch" style="max-height: 14em; max-width: 1300px;">
        <v-col cols="3" class="max-miniView-size">
          <littleLoginView :dark="dark"/>
        </v-col>
        <v-col cols="3" class="max-miniView-size">
          <littleCarritoView :dark="dark"/>
        </v-col>
        <v-col cols="3" class="max-miniView-size">
          <littleCategoryView :dark="dark"/>
        </v-col>
      </v-row>
      <div class="mt-4 mb-2 text-center blue-grey lighten-5">BANDERAS</div>
      <v-row style="max-width: 1300px;" class="mx-auto d-flex flex-row justify-space-around align-stretch">
        <v-col cols="3"     
          v-for="(flag, index) in ['--color-seating-flag', '--color-schedule-flag', '--color-hyperlink-flag', '--color-soon-flag']"
          :key="'flag-'+index"
        >
          <miniFlagView class="mx-auto" :flagColor="flag"/>
        </v-col>
      </v-row>
      
      <v-row class="pa-0 ma-0 mx-auto" style="max-width: 1300px;" v-if="!loadingInfo">
        <v-col cols="6" class="py-0">
          <v-switch
            class="ml-auto"
            v-show="currentTab!==1"
            color="black"
            v-model="dark"
            @change="changeDetected(currentTab)"
            :label="`Modo oscuro: ${dark?'Encendido':'Apagado'}`"
          ></v-switch>
        </v-col>
        <v-col cols="6" class="d-flex justify-end align-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="currentTab!==2 || (footerInfo && footerInfo.length === 3)"
                color="black"
                dark
                @click="$refs.FooterConfigToolRef.typeOfColumnConfig()"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Agregar columna</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-tabs class="pa-0" 
            v-model="currentTab"
            @change="validChangesBefore"
            vertical 
            active-class="indigo--text" 
          >
            <v-tab :disabled="changeOnTab !== false">
              <v-icon left>
                mdi-palette
              </v-icon>
              Colores
            </v-tab>
            <v-tab :disabled="changeOnTab !== false">
              <v-icon left>
                mdi-image-edit
              </v-icon>
              Imágenes
            </v-tab>
            <v-tab :disabled="changeOnTab !== false">
              <v-icon left>
                mdi-brush
              </v-icon>
              Footer
            </v-tab>

            <v-tab-item class="py-0" transition="fade-transition" reverse-transition="fade-transition">
              <div>
                <ColorConfigTool 
                  ref="ColorConfigToolRef"
                  v-if="colors"
                  :colors="colors" 
                  :darkMode="dark"
                  @updateColors="(updatedColors, newDark) => {
                    this.colors = updatedColors
                    this.dark = newDark
                    this.darkCopy = newDark
                  }"
                  @changeDetected="changeDetected"
                />
              </div>
            </v-tab-item>
            <v-tab-item transition="fade-transition" reverse-transition="fade-transition">
              <div>
                <ImagesConfigTool 
                  ref="ImagesConfigToolRef"
                  :bgImage="bgImage" 
                  :logo="logo"
                  :loading="loadingInfo"
                  @changeDetected="changeDetected"
                />
              </div>
            </v-tab-item>
            <v-tab-item transition="fade-transition" reverse-transition="fade-transition">
              <div>
                <FooterConfigTool 
                  ref="FooterConfigToolRef"
                  :footerInfo="footerInfo" 
                  :dark="dark"
                  @updatefooter="(updatedFooter, newDark) => {
                    this.footerInfo = updatedFooter
                    this.dark = newDark
                    this.darkCopy = newDark
                  }"
                  @changeDetected="changeDetected"
                />
              </div>
            </v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import littleCategoryView from '../../../components/configuration/littleViews/categoryView.vue'
import littleMapView from "../../../components/configuration/littleViews/mapView.vue"
import littleLoginView from "../../../components/configuration/littleViews/miniLoginView.vue"
import miniPayConfirmView from "../../../components/configuration/littleViews/miniPayConfirmView.vue"
import littleMainView from "../../../components/configuration/littleViews/miniHomeView.vue"
import littleCarritoView from "../../../components/configuration/littleViews/carritoView.vue"
import colorPickerTool from "../../../components/Tools/colorPickerTool.vue"
import ColorConfigTool from "../../../components/configuration/styleConfigTools/colorConfigTool.vue"
import FooterConfigTool from "../../../components/configuration/styleConfigTools/footerConfigTool.vue"
import ImagesConfigTool from "../../../components/configuration/styleConfigTools/imagesConfigTool.vue"
import miniFlagView from '../../../components/configuration/littleViews/miniFlagView.vue'


export default {
  name: "ColorsWepshop",
  components: {
    littleMainView,
    littleMapView,
    littleLoginView,
    miniPayConfirmView,
    littleCategoryView,
    littleCarritoView,
    colorPickerTool,
    ColorConfigTool,
    FooterConfigTool,
    ImagesConfigTool,
    miniFlagView
  },
  data() {
    return {
      colors: false,
      dark: false,
      footerInfo: false,
      currentTab: null,
      logo: false,
      bgImage: false,
      loadingInfo: false,

      darkCopy: false,
      changeOnTab: false
    }
  },
  mounted() {
    this.loadStyles()
  },
  methods: {
    loadStyles() {
      this.loadingInfo = true
      this.$root.post("/Admin/getCorpStyles", {
      })
      .then((response) => {
        let info = response.data;
        if(info.success == 'TRUE') {
          info = info.Data[0]
          this.bgImage = info.bg_image
          this.logo = info.logo
          this.colors = info.colors
          for(var name in info.colors){
            document.documentElement.style.setProperty(name, info.colors[name]);
          }
          this.footerInfo = info.footerinfo;
          this.dark = info.dark_on
          this.darkCopy = info.dark_on
        } else {
          this.$root.swalAlert("error", "Error al cargar estilos", info.Msg);
        }
      })
      .catch(e => {
        this.$root.swalAlert("error", "Error", e.message);
      })
      .finally(() => {
        this.loadingInfo = false
      });
    },
    saveAnyChanges(pendingTab) {
      const saveTab = pendingTab || this.currentTab
      switch (saveTab) {
        case 0:
          this.$refs.ColorConfigToolRef.saveColors()
          break;
        case 1: 
          this.$refs.ImagesConfigToolRef.saveChanges()
          break;
        case 2:
          this.$refs.FooterConfigToolRef.saveOrden()
          break;
      }
    },
    changeDetected(tab) {
      this.changeOnTab = tab
    },
    async validChangesBefore(val) {
      if(this.changeOnTab === false) return;

      const confirm = await this.$swal({
        title: "¿Desea guardar los cambios?",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: false,
        reverseButtons: true,
        confirmButtonText: "Sí, por favor",
      });

      if (!confirm.isConfirmed) {
        this.discardAnyChanges()
        return;
      } 

      this.saveAnyChanges(this.changeOnTab)
    },
    discardAnyChanges() {
      switch (this.changeOnTab) {
        case 0:
            this.dark = this.darkCopy
            this.$refs.ColorConfigToolRef.discardChanges()
          break;
        case 1:
            this.$refs.ImagesConfigToolRef.discardChanges()
          break;
        case 2:
            this.dark = this.darkCopy
            this.$refs.FooterConfigToolRef.discardChanges()
          break;
      }
    }
  }
}
</script>

</script>

<style scoped>
  .max-miniView-size {
    max-height: 200px;
    overflow: hidden;
  }
</style>