<template>
  <div>
    <v-color-picker 
      hide-mode-switch 
      mode="hexa" 
      v-model="colorComputed">
    </v-color-picker>
  </div>
</template>

<script>
export default {
  name: "colorPickerTool",
  props: {
    value: {
      type: String,
      required: true,
    }
  },
  data () {
    return {
      hexa: '#FF00FFFF'
    }
  },
  computed: {
    colorComputed: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', this[this.hexa] = v)
      },
    },
  },
  watch: {
    colorComputed: function (val) {
      this.$emit('change', val)
    }
  },
  methods: {
    showColor () {
      if (typeof this.colorComputed === 'string') return this.colorComputed

      return JSON.stringify(Object.keys(this.colorComputed).reduce((color, key) => {
        color[key] = Number(this.colorComputed[key].toFixed(2))
        return color
      }, {}), null, 2)
    }
  }
}
</script>