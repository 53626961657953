<template>
  <div class="card-event">
    <div class="ribbon ribbon-top-right"
      :class="[
          {'OutPage-event-flag': flagColor == '--color-hyperlink-flag'}, 
          {'scheduled-event-flag': flagColor == '--color-schedule-flag'}, 
          {'seating-event-flag': flagColor == '--color-seating-flag'}, 
          {'soon-event-flag': flagColor == '--color-soon-flag'}
        ]">
      <span class="text-uppercase" style="font-size: 11px !important;"> {{ flags[flagColor] }} </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "miniFlagView",
  props:["flagColor"],
  data () {
    return {
      flags: {        
        "--color-seating-flag": "Butacas",
        "--color-schedule-flag": "Fechas",
        "--color-hyperlink-flag": "Hipervínculo",
        "--color-soon-flag": "Próximamente",
      }
    }
  }
}
</script>

<style scoped>
.card-event {
  position: relative;
  border-radius: 5px;
  max-width: 200px;
  min-height: 120px;
  background: #fff;
  color: #fff;
  font-weight: 600;
  border: 1.5px solid #3F414A;
  overflow: hidden;
}
.ribbon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20%;
  overflow: hidden;
  position: absolute;
}
.ribbon-top-right {
  top: calc(20%);
  left: calc(38%);
  right: calc(-25%);
  transform: rotate(45deg);
}
.soon-event-flag{
  background: var(--color-soon-flag) !important;
}
.OutPage-event-flag{
  background: var(--color-hyperlink-flag) !important;
}
.scheduled-event-flag{
  background: var(--color-schedule-flag) !important;
}
.seating-event-flag{
  background: var(--color-seating-flag) !important;
}
</style>