<template>
  <v-card class="pa-0" width="100%" height="100%" style="overflow: hidden;" 
    :style="{
      'background': `linear-gradient(
        to bottom,
        ${dark ? 
        '#000000 0%, var(--color-bg-others) 100%':'#E2E6EF 0%, var(--color-bg-others) 100%'}
      )`}"
    >
    <div class="nav-bar d-flex align-center justify-space-between" 
    :class="[{'white--text': dark}, {'black--text': !dark}]">
      <div>LOGO</div>
      <v-icon class="mx-1" :dark="dark" size="1.7em">mdi-close</v-icon>
    </div>
    <div class="login-background">
      <div class="login-background-image d-flex justify-center align-center">
        <div class="box-login px-2 py-2">
          <div class="login title-lg">iniciar Sesión</div>
          <div class="login subtitle-lg">¿Eres nuevo aquí? <b class="subtitle-lg special-lg">Registrarse</b></div>
          <div class="login subtitle-lg box-input-lg d-flex align-center px-1 mt-1">
            ejemplo@gmail.com
          </div>
          <div class="my-1 login subtitle-lg box-input-lg d-flex align-center px-1">
            contraseña
          </div>
          <div class="subtitle-lg no-soy-robot mx-auto d-flex flex-row align-center justify-start">
            <div class="checkit-box"></div>
            <div>No soy un robot</div>
          </div>
          <div class="d-flex justify-space-between align-center">
            <div class="login subtitle-lg">Recordarme</div>
            <b class="subtitle-lg special-lg">¿Olvidaste tu contraseña?</b>
          </div>
          <div :class="[{'white--text': dark}, {'black--text': !dark}]" 
            class="button-login special-btn login subtitle-lg ">
            INICIAR SESIÓN
          </div>
          <div class="button-login face-btn login subtitle-lg ">
            INICIAR SESIÓN CON FACEBOOK
          </div>
          <div class="button-login gg-btn login subtitle-lg black--text">
            Registrarse con Google
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "littleLoginView",
  props: ["dark"],
  data () {
    return {

    }
  }
}
</script>

<style scoped>
  .nav-bar {
    height: 10%;
    font-size: 0.4em;
    padding: 1% 5%;
    background: linear-gradient( 207deg, 
        var(--color-primary-light) 0%, 
        var(--color-primary) 45%, 
        var(--color-primary-light1) 99% 
      ) !important;
  }
  .login-background {
    position: relative;
    width: 100%;
    height: 90%;
  }
  .login-background-image {
    background-color: #5b5c5fa1;
    width: 100%;
    height: 100%;
  }
  .box-login {
    position: absolute;
    top: calc(15%);
    left: calc(35%);
    background-color: #d8d8d8d2;
    height: 75%;
    width: 30%;
  }
  .login {
    line-height: 1.6em;
    color: white;
  }
  .title-lg {
    font-size: 0.5em;
  }
  .subtitle-lg {
    font-size: 0.2em;
  }
  .special-lg {
    color: var(--color-primary);
  }
  .box-input-lg {
    border: 1px solid var(--color-primary);
    background-color: rgb(9, 9, 17);
    height: 3em;
  }
  .checkit-box {
    padding: 0.6em;
    background-color: antiquewhite;
  }
  .no-soy-robot {
    padding: 1em;
    width: 50%;
    height: 2.3em;
    background-color: black;
    color: white;
  }
  .button-login {
    margin-bottom: 1em;
    padding: 0.3em 0em;
    text-align: center;
  }
  .special-btn  {
    background-color: var(--color-primary);
  }
  .face-btn {    
    background-color: #2761ac;
  }
  .gg-btn {
    background-color: #f3f3f3;
  }
</style>