<template>
  <v-card class="pa-0" width="100%" height="100%" style="overflow: hidden;">

    <div class="images-bar d-flex flex-column justify-center" :class="[{'white--text': dark}, {'black--text': !dark}]"> 
      <div class="px-2">
        <div class="titleEvent-style py-0  d-flex align-center justify-space-between">
          <div>Evento</div>
          <div class="d-flex flex-row">
            <v-icon class="mx-1" :dark="dark" size="1.2em">mdi-cart-outline</v-icon>
            <v-icon class="mx-1" :dark="dark" size="1.2em">mdi-home</v-icon>
          </div>
        </div>
        <div class="title-style py-0" style="margin-top: -1em;">
          Descripción
        </div>
      </div>
      <v-icon dark size="1.1em" color="grey lighten-2" class="icon-arrow-down">mdi-arrow-down</v-icon>
    </div>

    <div class="pl-5" :style="{'background-color': dark ? 'black':'#E2E6EF'}"> 
      <div class="d-flex px-1" :class="[{'white--text': dark}, {'black--text': !dark}]"> 
        <div v-for="item of categorys">
        <div class="subtitle-style px-1 tab-style" :class="[{'active':item.active},{'light':!dark}]">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    

    <div v-for="event in events" :style="{'background-color': dark ? 'black':'#E2E6EF'}">
        <div class="d-flex justify-space-around">
          <div class="py-1 px-1 align-center">
              <div class="card-event d-flex">
                <div class="d-flex justify-center cover-style picture">
                  <v-icon dark>mdi-image</v-icon>
                </div>
                <div class="px-2">
                  <div class="title-style" 
                    :class="[{'white--text': dark}, {'black--text': !dark}]">{{ event.title }}</div>
                  <div class="subtitle-style" 
                    :class="[{'white--text': dark}, {'black--text': !dark}]">{{ event.subtitle }}</div>
                  <div class="notification-style"
                    :class="[{'white--text': dark}, {'black--text': !dark}]"><v-icon :dark="dark">mdi-calendar</v-icon> {{ event.notification }}</div>
                </div>
              </div>
          </div>
          <div>
            <div class="find-button d-flex justify-center align-center" :class="[{'white--text': dark}, {'black--text': !dark}]">
              VER BOLETOS
            </div>
          </div>
        </div>
    </div>

  </v-card>
</template>

<script>
export default {
  name: "littleMainView",
  props: ["dark"],
  data () {
    return {
      categorys:[
        {
          name:'Category 1',
          active:'active'
        },
        {
          name:'Category 2',
          active:null
        },
      ],
      events: [
        {
          id_event: 1,
          title: "Title 1",
          subtitle: "Subtitle",
          notification: "Notificacion"
        },
        {
          id_event: 2,
          title: "Title 2",
          subtitle: "Subtitle",
          notification: "Notificacion"
        }
      ],
      tab:1
    }
  }
}
</script>

<style scoped>
  .tab-style{
    margin: 0.5em 0em 0em 0em;
    border-radius: 6px 6px 0px 0px;
    height: 20px;
    border-bottom: 2px solid #252525;
  }
  .light {  
  border-bottom: 2px solid #c0c0c0;
  }
  .tab-style.active{
    background: #242424;
  }
  .tab-style.light.active {
    background: #c9d2db !important;
  }
  .images-bar {
    background-color: rgb(151, 151, 151);
    height: 30%;
  }
  .card-event {
    line-height: 0.8em;
  }
  .card-event .picture{
    width: 4em;
    height: 2.5em;
  }
  .icon-arrow-down{
    margin-top: -5px;
  }
  .titleEvent-style {
    font-size: 0.8em ;
  }
  .title-style {
    font-size: 0.6em;
  }
  .subtitle-style {
    font-size: 0.5em;
  }
  .notification-style{
    font-size: 0.4em;
  }
  .notification-style .v-icon{
    font-size: 2em !important;
  }
  .cover-style {    
    height: 2em;
    width: 100%;
    background-color: grey;
  }
  .find-button {
    background-color: var(--color-primary);
    width: fit-content;
    height: 0.7rem;
    font-size: 0.35em;
    padding: 0.5rem 0.7rem;
    margin-top: 0.5rem;
  }
</style>